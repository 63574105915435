<template>
  <works-frame-logo :articleID="articleID">
    <template #h2>Alain Gousse Sommelier</template>
    <template #h3
      ><span>#front-end</span><span>#web design</span
      ><span>#logo</span></template
    >
    <template #sub>Site de vente en ligne de vins</template>
    <template #date>2006</template>
    <template #intro>
      Alain Gousse est sommelier dans un restaurant étoilé. Il donne des
      conseils pour accorder mets et vins, partage ses coups de coeur du moment
      et propose la vente de vins en ligne.
    </template>
    <template #imageTop>
      <img src="@/assets/img/works/ag_logo.jpg" />
    </template>
    <template #image2><img src="@/assets/img/works/ag_flyer.jpg" /></template>
  </works-frame-logo>
</template>
<script>
import WorksFrameLogo from "./WorksFrameLogo.vue";
export default {
  components: { WorksFrameLogo },
  name: "WorkAG",
  data() {
    return {
      articleID: 5,
    };
  },
  mounted() {
    this.$emit("emitID", this.articleID);
  },
};
</script>