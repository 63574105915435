<template>
  <section class="main">
    <h2><slot name="h2"></slot></h2>
    <h3><slot name="h3"></slot></h3>
    <section class="sectionTop">
      <div class="intro">
        <h4>What</h4>
        <h5><slot name="sub"></slot> | <slot name="date"></slot></h5>
        <slot name="intro"></slot>
      </div>
      <div class="imageTop">
        <slot name="imageTop"></slot>
      </div>
    </section>
    <section :class="'section-' + articleID">
      <div class="image" :class="'image-' + articleID + '-1'">
        <slot name="image2"></slot>
        <slot name="image4"></slot>
      </div>
      <div class="image" :class="'image-' + articleID + '-2'">
        <slot name="image3"></slot>
      </div>
    </section>
  </section>
</template>
<script>
export default {
  name: "WorksFrameLogo",
  props: ["articleID"],
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
$beige: #f2f2ec;
$sub: #848484;
/*.main {
  margin-top: -48px;
}*/
h3 {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  > * {
    margin: 0 8px;
  }
}
.sectionTop {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.intro {
  box-sizing: border-box;
  background-color: $beige;
  width: 100%;
  padding: 15px;
  position: relative;
  margin: 10px 0 10px 0;
  animation: 1s slidein;
  text-align: left;
  line-height: 1.35;
  h4 {
    display: none;
  }
  h5 {
    color: $sub;
    font-size: 17px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    margin: 10px 0 15px 0;
  }
}
.imageTop {
  width: 500px;
  height: auto;
  margin: 10px 0 0 0;
  transform: translateY(80px);
  animation: 1s slidein 0.3s forwards;
  img {
    width: 100%;
    height: auto;
  }
}
.image {
  max-width: 1200px;
  margin: 20px auto;
  img {
    width: 100%;
    height: auto;
  }
}
.section-3 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  margin: auto;
  .image-3-1 {
    width: calc(50% - 20px);
    img + img {
      margin-top: 20px;
    }
  }
  .image-3-2 {
    width: calc(50% - 20px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 536px) {
  .main {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 768px) {
  .sectionTop {
    flex-wrap: inherit;
  }
  .intro {
    width: 375px;
    h4 {
      display: block;
      font-size: 20px;
      font-weight: bold;
      margin: 10px 0 15px 0;
    }
  }
  .imageTop {
    margin: 10px 0 10px 20px;
  }
  .image {
    margin: 30px auto;
  }
  .section-3 {
    .image-3-1 {
      width: calc(50% - 30px);
      img + img {
        margin-top: 30px;
      }
    }
    .image-3-2 {
      width: calc(50% - 30px);
      margin-left: 30px;
    }
  }
}
@media screen and (min-width: 920px) {
  .intro {
    height: 375px;
    padding: 30px;
    margin: 30px;
    line-height: 1.5;
    h4 {
      margin: 10px 0 20px 0;
    }
    h5 {
      margin: 10px 0 20px 0;
    }
  }
  .imageTop {
    margin: 30px;
  }
  .section-7 {
    display: flex;
    justify-content: center;
    max-width: 1400px;
    margin: auto;
    .image-7-1 {
      width: calc(50% - 30px);
      img + img {
        margin-top: 30px;
      }
    }
    .image-7-2 {
      width: calc(50% - 30px);
      margin-left: 30px;
    }
  }
}
@keyframes slidein {
  from {
    transform: translateY(80px);
  }
  to {
    transform: translateY(0);
  }
}
</style>