<template>
  <works-frame-logo :articleID="articleID">
    <template #h2>Maison Terre et Pierre</template>
    <template #h3>
      <span>#logo</span><span>#print</span><span>#web design</span
      ><span>#front-end</span></template
    >
    <template #sub>Constructeur de maison individuelle</template>
    <template #date>2008</template>
    <template #intro>
      La marque se situe du moyen au haut de gamme, elle est complémentaire de
      Primo House, qui est l'entrée de gamme. L'entreprise a donc besoin de deux
      styles qui se distinguent.
    </template>
    <template #imageTop>
      <img src="@/assets/img/works/mtp_logo.jpg" />
    </template>
    <template #image2><img src="@/assets/img/works/mtp_mac.jpg" /></template>
    <template #image3
      ><img src="@/assets/img/works/mtp_kakemono.jpg"
    /></template>
    <template #image4><img src="@/assets/img/works/ph_paper.jpg" /></template>
  </works-frame-logo>
</template>
<script>
import WorksFrameLogo from "./WorksFrameLogo.vue";
export default {
  components: { WorksFrameLogo },
  name: "WorkMTP",
  data() {
    return {
      articleID: 3,
    };
  },
  mounted() {
    this.$emit("emitID", this.articleID);
  },
};
</script>