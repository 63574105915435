<template>
  <div>
    <div class="knockout">
      <!-- svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
        <defs>
          <pattern id="border">
            <path
              x="0"
              y="0"
              width="73"
              height="18"
              d="M73,9c0,4.971-4.029,9-9,9H9c-4.971,0-9-4.029-9-9l0,0c0-4.971,4.029-9,9-9h55C68.971,0,73,4.029,73,9L73,9z"
            />
          </pattern>
        </defs>
        <rect fill="url(#border)" x="0" y="0" width="100%" height="100%" />
      </svg -->
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="Gradient1">
            <stop offset="5%" stop-color="#e5fc68" />
            <stop offset="95%" stop-color="#00e4ba" />
          </linearGradient>
          <linearGradient id="Gradient2" x1="0" x2="1" y1="0" y2="1">
            <stop offset="0%" stop-color="#e5fc68" />
            <stop offset="80%" stop-color="#ffd3bb" />
          </linearGradient>

          <path
            id="path1"
            transform="rotate(135 30 30)"
            fill="url(#Gradient2)"
            d="M73,9c0,4.971-4.029,9-9,9H9c-4.971,0-9-4.029-9-9l0,0c0-4.971,4.029-9,9-9h55C68.971,0,73,4.029,73,9L73,9z"
          />
          <path
            id="path2"
            transform="rotate(240 60 18)"
            fill="url(#Gradient1)"
            d="M73,9c0,4.971-4.029,9-9,9H9c-4.971,0-9-4.029-9-9l0,0c0-4.971,4.029-9,9-9h55C68.971,0,73,4.029,73,9L73,9z"
          />
          <path
            id="path3"
            transform="rotate(190 40 20)"
            d="M73,9c0,4.971-4.029,9-9,9H9c-4.971,0-9-4.029-9-9l0,0c0-4.971,4.029-9,9-9h55C68.971,0,73,4.029,73,9L73,9z"
          />
          <path
            id="path4"
            d="M75.285,6.143c1.611,4.979-3.167,10.167-9.667,12.333c-5.15,1.716-15.833,4.834-28,5.167c-12.338,0.338-23-3.667-28.667-6
	s-11-6.833-8.167-13.167c2.296-5.132,5.666-4.833,9.833-4s17.333,7,27.333,6.667c10-0.333,24.167-5.667,27.667-6.667
	S73.451,0.476,75.285,6.143z"
          />
          <pattern id="Pattern1" x="0" y="0" width=".35" height=".25">
            <path
              transform="rotate(135 30 30)"
              fill="url(#Gradient2)"
              d="M73,9c0,4.971-4.029,9-9,9H9c-4.971,0-9-4.029-9-9l0,0c0-4.971,4.029-9,9-9h55C68.971,0,73,4.029,73,9L73,9z"
            />
          </pattern>
          <!-- pattern id="Pattern" x="0" y="0" width=".25" height=".35">
            <path
              transform="rotate(240 60 18)"
              fill="url(#Gradient1)"
              d="M73,9c0,4.971-4.029,9-9,9H9c-4.971,0-9-4.029-9-9l0,0c0-4.971,4.029-9,9-9h55C68.971,0,73,4.029,73,9L73,9z"
            />
          </pattern>
          <pattern id="Pattern2" x="0" y="0" width=".35" height=".25">
            <path
              transform="rotate(135 30 30)"
              fill="url(#Gradient2)"
              d="M73,9c0,4.971-4.029,9-9,9H9c-4.971,0-9-4.029-9-9l0,0c0-4.971,4.029-9,9-9h55C68.971,0,73,4.029,73,9L73,9z"
            />
          </pattern>
          <pattern id="Pattern3" x="0" y="0" width=".45" height=".45">
            <path
              transform="rotate(190 40 20)"
              d="M73,9c0,4.971-4.029,9-9,9H9c-4.971,0-9-4.029-9-9l0,0c0-4.971,4.029-9,9-9h55C68.971,0,73,4.029,73,9L73,9z"
            />
          </pattern>
        </defs>
        <rect fill="url(#Pattern)" x="0" y="0" width="100%" height="100%" />
        <rect
          fill="url(#Pattern2)"
          x="100"
          y="-30"
          width="100%"
          height="100%"
        />
        <rect fill="url(#Pattern3)" x="200" y="40" width="100%" height="100%" / -->
        </defs>
        <use href="#path3" x="30" y="140" transform="rotate(-20)" />
        <use href="#path3" x="-270" y="240" transform="rotate(-40)" />
        <use href="#path3" x="80" y="520" />
        <use href="#path3" x="-50" y="720" />
        <use href="#path3" x="300" y="-40" />
        <use href="#path3" x="550" y="60" />
        <use href="#path3" x="640" y="220" transform="rotate(20)" />
        <use href="#path3" x="300" y="-980" transform="rotate(90)" />
        <use href="#path3" x="-450" y="-1200" transform="rotate(110)" />
        <use href="#path3" x="1230" y="750" transform="rotate(-20)" />
        <use href="#path3" x="850" y="1000" transform="rotate(-20)" />
        <use
          href="#path1"
          v-for="(data, index) in 10"
          :key="index"
          :x="index * 200"
          :y="-400 + index * 50"
        />
        <use
          href="#path2"
          v-for="(data, index) in 10"
          :key="index"
          :x="index * 200"
          :y="-200 + index * 50"
        />
        <use
          href="#path1"
          v-for="(data, index) in 10"
          :key="index"
          :x="index * 200"
          :y="index * 50"
        />
        <use
          href="#path2"
          v-for="(data, index) in 10"
          :key="index"
          :x="index * 200"
          :y="200 + index * 50"
        />
        <use
          href="#path1"
          v-for="(data, index) in 10"
          :key="index"
          :x="index * 200"
          :y="400 + index * 50"
        />
        <use
          href="#path2"
          v-for="(data, index) in 10"
          :key="index"
          :x="index * 200"
          :y="600 + index * 50"
        />
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: "BgMotif",
  data() {
    return {
      rotateValue: [0, 20, 40, 60, 80, 100, 120, 140, 160, 180, 200],
    };
  },
};
</script>
<style lang="scss" scoped>
.knockout {
  position: fixed;
  right: 0;
  top: 0;
  bottom: auto;
  z-index: -1;
  //background: url("../assets/img/border.svg");
}
</style>