<template>
  <main>
    <header>
      <h1 @click="getID(0)">
        <router-link to="/work">WORKS</router-link>
      </h1>
      <!-- nav>
        <span @click="setProdIdWork(0), allArticles()">all</span>
        <span @click="setProdIdWork(0), selectArticle(['a2', 'a4', 'a1', 'a9'])"
          >#front-end</span
        >
        <span
          @click="
            setProdIdWork(0), selectArticle(['a9', 'a3', 'a4', 'a5', 'a7'])
          "
          >#web design</span
        >
        <span @click="setProdIdWork(0), selectArticle(['a6', 'a3', 'a5', 'a7'])"
          >#logo</span
        >
        <span @click="setProdIdWork(0), selectArticle(['a6', 'a3', 'a7', 'a8'])"
          >#print</span
        >
      </nav -->
      <nav id="navSub" v-if="articleID > 0">
        <router-link
          :to="link[prevId]"
          class="prev"
          :disabled="articleID == 0"
          @click="prev"
        ></router-link>
        <router-link
          :to="link[nextId]"
          class="next"
          :disabled="prodId == 9"
          @click="next"
        ></router-link>
      </nav>
    </header>
    <transition
      ><router-view @emitID="getID" @setProdIdWork="setProdIdWork"
    /></transition>
    <!-- nav v-if="prodId !== 0" id="navSub">
      <button class="prev" :disabled="prodId == 1" @click="prev"></button>
      <button class="next" :disabled="prodId == 9" @click="next"></button>
    </nav -->
    <!-- transition>
      <WorkInedito v-if="prodId == 6" />
      <WorkEJC v-if="prodId == 9" />
      <WorkMTP :prodId="prodId" v-if="prodId == 3" />
      <WorkColexo v-if="prodId == 4" />
      <WorkAG v-if="prodId == 5" />
      <Work1 v-if="prodId == 1" />
      <WorkPH :prodId="prodId" v-if="prodId == 7" />
      <WorkWine v-if="prodId == 8" />
      <Work2 v-if="prodId == 2" />
    </transition -->
  </main>
</template>

<script>
export default {
  name: "WorksTop",
  //props: ["prodId", "selectedProd"],
  data() {
    return {
      articleID: 0,
      link: [
        "boutique-du-bois",
        "ponsard-et-dumas",
        "maisons-terre-et-pierres",
        "colexo",
        "alain-gousse",
        "inedito",
        "primo-house",
        "vin",
        "ejccrossing",
      ],
      articleImage: ["inedito_carte", "mtp_logo"],
      articleTitle: [
        "La Boutique du Bois",
        "Ponnsard et Dumas",
        "Maison Terre et Pierres",
        "Colexo",
        "Alain Gousse",
        "Inedito",
        "Primo House",
        "Vin de Pays d'Oc",
        "Euro Japan Crossing",
      ],
      articleTag: [
        "#front-end",
        "#front-end",
        "#front-end #web design #logo #print",
        "#front-end #web design",
        "#front-end #web design #logo",
        "#logo #print",
        "#front-end #web design #logo #print",
        "#print",
        "#front-end #web design",
      ],
    };
  },
  mounted() {
    this.allArticles();
  },
  computed: {
    articles() {
      const arr = [];
      const children = document.getElementById("list").childNodes;
      for (let i = 1; i < children.length; i++) {
        arr.push(children[i].className);
      }
      return arr;
    },
    nextId() {
      let num = parseInt(this.articleID);
      return num;
    },
    prevId() {
      let num = parseInt(this.articleID);
      return (num -= 2);
    },
  },
  methods: {
    selectArticle(array) {
      this.allArticles();
      const children = document.getElementById("list").childNodes;
      for (let i = 0; i < children.length; i++) {
        if (!array.includes(children[i].className)) {
          children[i].classList.add("hide");
        }
      }
    },
    allArticles() {
      const children = document.getElementById("list").childNodes;
      for (let i = 0; i < children.length; i++) {
        children[i].classList.remove("hide");
      }
    },
    setProdIdWork(prodId) {
      //this.$emit("setProdIdWork", prodId);
      this.prodId = prodId;
    },
    getID(articleID) {
      this.articleID = articleID;
    },
    prev() {
      this.prodId -= 1;
    },
    next() {
      this.prodId += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
$nav: #939393;
$beige: #f2f2ec;
.hide {
  display: none;
  transition: all 0.3s;
}
main {
  padding: 0 15px;
  margin-bottom: 70px;
}
nav {
  display: flex;
}
header {
  margin: 35px auto 20px auto;
  display: flex;
  flex-wrap: wrap;
  padding-left: 3px;
  h1 {
    font-size: 2em;
    margin: 0 30px 0 0;
    a {
      color: $main;
      text-decoration: none;
    }
  }
  nav {
    flex-wrap: wrap;
    > * {
      color: $nav;
      border-radius: 4px;
      border: 1px solid $nav;
      padding: 1px 8px;
      text-decoration: none;
      margin: 12px 3px;
      font-size: 12px;
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        color: black;
        border-color: black;
      }
    }
  }
}
#navSub {
  > * {
    width: 30px;
    height: 30px;
    margin: 6px;
    background-color: white;
    border: 1px solid $nav;
    background: url("../assets/img/arrow-nav.svg") no-repeat center center;
    background-size: 30%;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background: $main url("../assets/img/arrow.svg") no-repeat center center;
      background-size: 30%;
    }
    &:disabled {
      opacity: 0.3;
      cursor: inherit;
      &:hover {
        background: url("../assets/img/arrow-nav.svg") no-repeat center center;
        background-size: 30%;
      }
    }
  }
  .prev {
    transform: rotate(180deg);
  }
}

//// WorksFrame /////
.h2Container {
  position: relative;
  width: 100%;
}
h3 {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 40px;
  > * {
    margin: 0 8px;
  }
}
@media screen and (min-width: 920px) {
  main {
    margin-bottom: 30px;
  }
  #list {
    justify-content: flex-start;
  }
  article {
    width: calc(31% - 30px);
    padding-top: calc(31% - 30px);
  }
  header {
    padding-left: 4em;
  }
}
</style>