<template>
  <div class="page home" id="home">
    <OneWave />
    <HomeText />
    <!-- HomeButton
      :style="'top:10%;right: 10%;'"
      :styleDelay="'animation-delay: 1s'"
      :styleContainer="'transform: rotate(195deg);'"
      >Work</HomeButton
    >
    <HomeLine :styleHomeLine="'top: 18%;right: 17%;'" />
    <HomeButton
      :style="'top:400px;right: 10%;'"
      :styleDelay="'animation-delay: 2s'"
      :styleContainer="'transform: rotate(165deg);'"
      >About</HomeButton
    >
    <HomeLine
      :styleHomeLine="'top: 500px;right: 17%;'"
      :styleDelay="'animation-delay: 1s'"
    / -->
  </div>
</template>

<script>
import OneWave from "../components/OneWave.vue";
import HomeText from "../components/HomeText.vue";
//import HomeButton from "../components/HomeButton.vue";
//import HomeLine from "../components/HomeLine.vue";
export default {
  name: "Home",
  components: {
    OneWave,
    HomeText,
    //HomeButton,
    //HomeLine,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss">
$fill: transparent;
$stroke: #333;
$stroke-width: 1;

.knockout {
  position: absolute;
  top: 0;
  /* Ensure the bottom layer is full screen */
  height: 100vh;
  width: 100%;
  /* Add a colorful texture and cutom font-styling */
  //background-image: linear-gradient(to left, #ff4e50, #f9d423);
  text-transform: uppercase;
  font-family: "Biryani", sans-serif;
}
text tspan {
  letter-spacing: -0.05em;
}
.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: url("#filter");
  overflow: hidden;
}
.ball {
  width: $radius;
  height: $radius;
  position: absolute;
  top: calc(50% - #{$radius/2});
  left: calc(50% - #{$radius/2});

  &:before {
    background: $one;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
  }

  &:nth-child(1) {
    transform: translate(-$radius * 2);
    animation: x-axis-lateral 8s infinite alternate ease-in-out;
    &:before {
      animation: y-axis-lateral 8s infinite 0.1s alternate ease-in-out;
    }
  }

  &:nth-child(2) {
    animation: x-axis 8s infinite alternate ease-in-out;
    &:before {
      animation: y-axis 8s infinite 0.5s alternate ease-in-out;
    }
  }

  &:nth-child(3) {
    transform: translate($radius * 1.1, $radius * 1.3);
    animation: x-axis-lateral 8s infinite alternate ease;
    &:before {
      animation: y-axis-lateral 8s infinite 0.4s alternate ease;
    }
  }
}

@keyframes x-axis {
  0% {
    transform: translate(-$radius * 1);
  }
  100% {
    transform: translate($radius * 1);
  }
}

@keyframes y-axis {
  0% {
    transform: translateY($radius * 0.7);
  }
  100% {
    transform: translateY(-$radius * 0.7) scale(0.8);
    background: $two;
  }
}

@keyframes x-axis-lateral {
  0% {
    transform: translate(-$radius * 1);
  }
  100% {
    transform: translate($radius * 0.8);
  }
}

@keyframes y-axis-lateral {
  0% {
    transform: translateY($radius/5);
  }
  100% {
    transform: translateY(-$radius * 0.5);
    background: $three;
  }
}

#home {
  height: 100vh;
  //width: 100vw;
  display: block;
  overflow: hidden;
}
.test {
  animation: float 6s ease-out 4s infinite;
}
@keyframes float {
  from {
    width: 10%;
    opacity: 1;
  }
  50% {
    width: 11%;
    opacity: 0.5;
  }
  to {
    width: 10%;
    opacity: 1;
  }
}
@keyframes circle {
  to {
    stroke-dashoffset: 0;
  }
}
</style>