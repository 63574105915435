<template>
  <works-frame-logo :articleID="articleID">
    <template #h2>Primo House</template>
    <template #h3
      ><span>#front-end</span><span>#web design</span
      ><span>#logo</span></template
    >
    <template #sub>Constructeur de maison individuelle</template>
    <template #date>2008</template>
    <template #intro>
      L'entrée de gamme pour ce promoteur immobilier, le ton de fraicheur et de
      légèreté a été donné pour sa communication visuelle.
    </template>
    <template #imageTop>
      <img src="@/assets/img/works/ph_logo.jpg" />
    </template>
    <template #image2><img src="@/assets/img/works/ph_paper.jpg" /></template>
    <template #image3><img src="@/assets/img/works/ph_web.jpg" /></template>
  </works-frame-logo>
</template>
<script>
import WorksFrameLogo from "./WorksFrameLogo.vue";
export default {
  components: { WorksFrameLogo },
  name: "WorkPH",
  data() {
    return {
      articleID: 7,
    };
  },
  mounted() {
    this.$emit("emitID", this.articleID);
  },
};
</script>