import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/scss/main.scss'
import LoadScript from 'vue-plugin-load-script';
import VueI18n from 'vue-i18n'
//import VueAos from 'vue-aos'
import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.use(LoadScript)
Vue.use(VueI18n);
//Vue.use(VueAos);
Vue.use(
  AOS.init({
    // add your settings here
  })
);
Vue.config.productionTip = false

const data = require('../traduction.json');
const i18n = new VueI18n({
  locale: 'fr',
  messages: data,
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
