<template>
  <nav id="nav">
    <router-link to="/">
      <span class="bg bgBlack menu-1"></span>
      <!-- span class="bg borderWhite borderWhite-1"></span -->
      <!-- span class="text menu-1">Home</span -->
      <span class="logo"></span>
    </router-link>
    <router-link to="/about">
      <span class="bg bgBlack menu-2"></span>
      <span class="text menu-2">About</span>
      <!-- div class="on icon bgBlack-2">
        <img src="../assets/img/about.svg" />
      </div -->
    </router-link>
    <router-link
      to="/work"
      @click="
        setProdId(0);
        onFlag();
      "
    >
      <span class="bg bgBlack menu-3"></span>
      <span class="text menu-3">Work</span>
    </router-link>
    <!-- div v-if="this.$route.path == '/work'" class="sub" :class="{ on: flag }">
      <a
        v-for="(data, index) in products"
        :key="index"
        @click="setProdId(data.productId)"
      >
      </a>
      <button type="button" @click="changeLocale" class="lang">JP / FR</button>
    </div -->

    <!-- router-link to="/contact">
      <span class="bg bgBlack menu-4"></span>
      <span class="text menu-4">Contact</span>
    </router-link -->
    <button type="button" @click="changeLocale" class="lang">JP / FR</button>
    <div class="space"></div>
    <router-link to="legalmentions" class="legal">Mentions légales</router-link>
  </nav>
</template>
<script>
export default {
  name: "Nav",
  data() {
    return {
      flag: false,
      work: false,
      title: "Work",
      products: [
        {
          productTitle: "La Boutique du Bois",
          productId: 1,
        },
        {
          productTitle: "Ponsard et Dumas",
          productId: 2,
        },
        /*{
          productTitle: "Takumi",
          productId: 3,
        },*/
      ],
    };
  },
  methods: {
    setProdId(prodId) {
      this.$emit("setProdId", prodId);
      //this.$router.push({ name: "Details", params: { Pid: prodId } });
    },
    onFlag() {
      this.flag = true;
      console.log(this.flag);
    },
    changeLocale() {
      this.$emit("change-locale");
    },
  },
};
</script>
<style lang="scss">
$menucolor0: #cc6bff /*#80d8cd*/;
$menucolor1: #ffbbc3 /*#aee0ff*/;
$menucolor2: #ffd8bb /*#8eeedf*/;

#nav {
  position: fixed;
  right: 0;
  bottom: 10px;
  height: 0;
  width: 100%;
  padding: 0 10px;
  z-index: 1;
  display: flex;
  background-color: black;
  //background: linear-gradient(0deg, $menucolor2 0%, $menucolor1 100%);
  animation: navBgM 0.7s ease 0.3s 1 forwards;
  //overflow: hidden;
  .sub {
    display: flex;
    align-items: center;
    > a {
      display: none;
    }
  }
  > a {
    color: rgb(201, 201, 201);
    text-decoration: none;
    padding: 10px;
    font-size: 14px;
    text-transform: uppercase;
    opacity: 0;
    position: relative;
    width: 5rem;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    animation: opacity 1s ease 0.5s 1 forwards;
    .logo {
      width: 100%;
      height: 100%;
      background: url("../assets/img/logoM.svg") no-repeat center center;
      opacity: 0;
      animation: opacity 0.7s ease 1s 1 forwards;
    }
    .bg {
      display: none;
    }
    .bgBlack {
      display: none;
    }
    .text {
      opacity: 0;
      animation: opacity 0.7s ease 1 forwards;
    }
    .borderWhite {
      background-color: transparent;
      border: 1px solid white;
    }
    .menu-1 {
      animation-delay: 1s;
    }
    .borderWhite-1 {
      animation-delay: 2.6s;
    }
    .menu-2 {
      animation-delay: 1.1s;
    }
    .menu-3 {
      animation-delay: 1.2s;
    }
    .menu-4 {
      animation-delay: 1.3s;
    }
    &.router-link-exact-active {
      color: #fff;
      font-weight: bold;
    }
    &:first-child.router-link-exact-active .logo {
      background: url("../assets/img/logo_on.svg") no-repeat center center;
    }
    &.legal {
      position: fixed;
      right: 0;
      bottom: 0;
      height: 15px;
      width: 100%;
      padding: 0;
      font-size: 10px;
      color: #c7c7c7;
      background-color: #7d7d7d;
    }
  }
  button {
    border: 0;
    outline: 0;
    background-color: transparent;
    color: $contentyellowDark;
    font-size: 11px;
    font-weight: 600;
    border-radius: 12px;
    padding: 5px;
    margin: 0;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
    animation: opacity 1s ease 1.2s 1 forwards;
  }
}
@media screen and (min-width: 920px) {
  #nav {
    position: fixed;
    right: 0;
    top: 0;
    bottom: auto;
    height: 0;
    width: auto;
    padding: 0 10px;
    z-index: 1;
    animation: navBg 1s ease 0.5s 1 forwards;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 1px;
      height: 100%;
      background: rgb(187, 187, 187);
    }
    .space {
      height: 10%;
    }
    .sub {
      display: block;
      margin-top: -30px;
      z-index: 1;
      opacity: 0;
      animation: opacity 0.7s ease 1.2s 1 forwards;
      &.on {
        animation-delay: 0.2s;
      }
      a {
        display: block;
        background-color: black;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        margin: 5px auto;
        cursor: pointer;
      }
    }
    > a {
      color: black;
      text-decoration: none;
      padding: 10px;
      font-size: 14px;
      text-transform: uppercase;
      opacity: 0;
      position: relative;
      width: 5.2em;
      height: 5.2em;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      .logo {
        background: url("../assets/img/logo.svg") no-repeat center center;
      }
      .icon {
        opacity: 0;
        animation: icon 0.4s ease 1 forwards;
      }
      .on {
        display: block;
        img {
          width: 100%;
          height: auto;
        }
      }
      .off {
        display: none;
      }
      .bg {
        display: block;
        position: absolute;
        z-index: -1;
        border-radius: 50%;
        opacity: 0;
        width: 100%;
        height: 100%;
        animation: navCircle 0.5s ease 1 forwards;
      }
      .bgBlack {
        display: block;
        //background-color: black;
        background-color: white;
        border: 1px solid rgb(187, 187, 187);
      }
      &.router-link-exact-active .bgBlack {
        background-color: black;
        color: white;
      }
      .borderWhite {
        background-color: transparent;
        border: 1px solid white;
      }
      .menu-1 {
        animation-delay: 1s;
      }
      .borderWhite-1 {
        animation-delay: 2.6s;
      }
      .menu-2 {
        animation-delay: 1.1s;
      }
      .menu-3 {
        animation-delay: 1.2s;
      }
      .menu-4 {
        animation-delay: 1.3s;
      }
      &:first-child {
        margin-top: 0;
        animation: navAlinkFirst 1s ease 0.5s 1 forwards;
      }
      &:not(:first-child) {
        margin-top: -20px;
        animation: navAlink 1s ease 0.5s 1 forwards;
      }
      &.router-link-exact-active {
        color: #fff;
        font-weight: bold;
      }
      &.legal {
        writing-mode: vertical-rl;
        position: absolute;
        right: 18px;
        bottom: 10px;
        font-size: 11px;
        text-align: right;
        margin: 0;
        padding: 10px;
        width: 5.2em;
        height: 5.2em;
        color: #585858;
        background-color: transparent;
      }
    }
    button {
      background-color: white;
      font-size: 13.33px;
      font-weight: 400;
      color: black;
      padding: 10px;
      margin: 10px auto;
    }
  }
}
@keyframes navBgM {
  from {
    height: 0;
  }
  to {
    height: 42px;
  }
}
@keyframes navBg {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}
@keyframes navAlink {
  from {
    opacity: 0;
    margin-top: -20px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes navAlinkFirst {
  from {
    opacity: 0;
    margin-top: 0;
  }
  to {
    opacity: 1;
    margin-top: 50px;
  }
}
@keyframes navCircle {
  from {
    opacity: 0;
    width: 50%;
    height: 50%;
  }
  to {
    opacity: 1;
    width: 100%;
    height: 100%;
  }
}
@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
