<template>
  <section class="page about" id="about">
    <div class="blockFlex">
      <div class="blockInBlockFlex blockLeft">
        <div class="subBlockLeft">
          <div class="blockImage">
            <img src="../assets/img/face.png" />
          </div>
          <div class="iconContainer">
            <div class="obj obj-1"></div>
            <div class="obj obj-2"></div>
            <div class="obj obj-3"></div>
            <div class="obj obj-4"></div>
            <div class="obj obj-5"></div>
            <div class="obj obj-6"></div>
            <div class="obj obj-7"></div>
            <div class="obj obj-8"></div>
          </div>
        </div>
      </div>
      <div class="blockInBlockFlex blockRight">
        <div class="blockInBlock blockText">
          <div v-if="this.$i18n.locale === 'ja'">
            <p>こんにちは、村岡恵美子です。</p>
            <p>
              私は「デザインする」事が好きです。<br />
              物事を観察し、使用者の立場に立ち、考察し、仮説を立て、作り、検証する。は、あらゆる物事と局面において、私のワークフローの根底にあります。
            </p>
            <p>
              私は「コミュニケーション」を大事にします。<br />
              伝えたい事が相手に伝わって初めて「思い」は生きてきます。それをいかに的確に伝えるか。いかに的確に受け取ってもらうか。各自の立場に立ち、「思い」が滞りなく共有される形を作る。正しいコミュニケーションは、世の中を少し分かり易くしてくれるのではないかと思っています。
            </p>
            <p>
              私は主にグラフィックデザイン、Webデザインとフロントエンド開発を職としています。<br />
              「思い」を視覚表現に落とし込む事。情報がスムーズに伝わるように構築する事。各デバイスで気持ちよく操作出来る仕組みを作る事。私は自分の仕事を、グラフィックとWebに特化したコミュニケーションデザインと捉えています。
            </p>
            <p>
              フランス、リヨンで2004年からフリーランスとして活動しています。
            </p>
            <div class="spec">
              <p>
                <span class="title">ツール</span>
                : Photoshop, Illustrator - Figma - VS Code - Git
              </p>
              <p>
                <span class="title">言語</span>
                : HTML, SCSS, Javascript, Vue
              </p>
              <p>
                <span class="title">CMS</span>
                : WordPress, Prestashop
              </p>
            </div>
          </div>
          <div v-if="this.$i18n.locale === 'fr'" class="fr">
            <p>
              Bonjour, je suis Emiko Muraoka, passionnée de design et de
              communication, freelance depuis 2004.
            </p>
            <p>
              Designer, c’est observer, réfléchir et créer. Le processus qui
              fait la base de mon workflow, à tous moments et pour chaque
              situation.
            </p>
            <p>
              Communication ; l’idée ne peut vivre qu’en la partageant. Ma
              mission est d’assurer que le message passe avec son sens le plus
              juste et qu’il circule sans encombre. Je pense qu'avec une
              communication juste, le monde est un peu plus facile à cerner.
            </p>
            <p>
              J'ai fait du design graphique, du web design et du développement
              frond-end mon métier.<br />
              Intégrer une idée dans une expression visuel. Structurer les
              informations pour mieux les présenter. Créer des composants pour
              une navigation agréable, sur tous les appareils. Mon métier
              concerne le design de communication, spécialisé dans la
              communication graphique et dans le web.
            </p>
            <p>Je vis et travaille à Lyon.</p>
            <div class="spec">
              <!-- <span class="title">Ce que je fais</span>
          :
          <ul>
            <li>
              Design graphique (Identité visuel (logo, outils de communication),
              Icone, Illustration, etc.)
            </li>
            <li>Web design</li>
            <li>Développement Front-end</li>
          </ul> -->
              <p>
                <span class="title">Outils</span>
                : Photoshop, Illustrator - Figma - VS Code - Git
              </p>
              <p>
                <span class="title">Langages</span>
                : HTML, SCSS, Javascript, Vue
              </p>
              <p>
                <span class="title">CMS</span>
                : WordPress, Prestashop
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "About",
  mounted() {
    if (this.isSafari()) {
      this.addSafari();
    }
  },
  methods: {
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
    addSafari() {
      const obj = document.getElementsByClassName("obj");
      for (var i = 0; i < obj.length; i++) {
        obj[i].classList.add("safari");
      }
    },
  },
  computed: {
    isJp: function () {
      if (this.$i18n.locale === "ja") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.spec {
  //background: rgb(243, 243, 243);
  text-align: left;
  //padding: 10px 20px;
  border-radius: 10px;
  //max-width: 470px;
  margin: 40px 0;
  p {
    margin: 8px 0;
  }
  .title {
    display: inline-block;
    //border-bottom: 1px solid #788088;
    margin-right: 2px;
    font-size: 0.9em;
    font-weight: 700;
    text-transform: uppercase;
    color: #e7979b;
  }
  ul {
    margin: 10px 0 10px 15px;
    padding: 0;
    list-style: "- ";
  }
}
.page.about {
  overflow-x: hidden;
}
.blockFlex {
  position: relative;
  display: flex;
  flex-direction: column;
}
.blockInBlockFlex {
  flex-shrink: 0;
  margin: 15px;
  width: calc(100% - 30px);
  position: relative;
}
.blockLeft {
  display: flex;
  align-items: center;
}
.subBlockLeft {
  height: 310px;
  margin-top: 20px;
  > * {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
.iconContainer {
  width: 290px;
}
.blockInBlock {
  width: 100%;
  height: auto;
  &.blockText {
    text-align: left;
    font-size: 1em;
    padding: 0.5rem;
    margin-bottom: 30px;
  }
}
.blockImage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 70px;
  width: 290px;
  > * {
    margin: auto;
    width: 100%;
    height: auto;
  }
}
.obj {
  position: absolute;
  top: 20px;
  left: 10px;
  background: tomato;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  opacity: 0;
  offset-path: path("M 0, 130 a 130,130 0 1,1 260,0 a 130,130 0 1,1 -260,0");
}
.obj-1 {
  animation: move-1 300ms 100ms forwards;
  background: transparent url("../assets/img/figma.svg") no-repeat center center;
  background-size: 65%;
  &.safari {
    top: 40px;
    left: 30px;
    transform: rotate(-40deg);
  }
}
.obj-2 {
  width: 4.5rem;
  height: 2.8rem;
  animation: move-2 400ms 100ms forwards;
  background: transparent url("../assets/img/sass.svg") no-repeat center center;
  background-size: 68%;
  &.safari {
    left: 160px;
    top: 0;
  }
}
.obj-3 {
  width: 3.1rem;
  height: 3.1rem;
  animation: move-3 500ms 100ms forwards;
  background: transparent url("../assets/img/js.svg") no-repeat center center;
  background-size: 70%;
  &.safari {
    left: 310px;
    top: 40px;
    transform: rotate(45deg);
  }
}
.obj-4 {
  width: 3.5rem;
  height: 3.5rem;
  animation: move-4 600ms 100ms forwards;
  background: transparent url("../assets/img/vue.svg") no-repeat center center;
  background-size: 70%;
  &.safari {
    left: 360px;
    top: 160px;
    transform: rotate(90deg);
  }
}
.obj-5 {
  animation: move-5 700ms 100ms forwards;
  background: transparent url("../assets/img/github.svg") no-repeat center
    center;
  background-size: 85%;
  &.safari {
    left: 300px;
    top: 300px;
    transform: rotate(135deg);
  }
}
.obj-6 {
  width: 3.6rem;
  height: 3.6rem;
  animation: move-6 800ms 100ms forwards;
  background: transparent url("../assets/img/html5.svg") no-repeat center center;
  background-size: 60%;
  &.safari {
    left: 160px;
    top: 360px;
    transform: rotate(180deg);
  }
}
.obj-7 {
  animation: move-7 900ms 100ms forwards;
  background: transparent url("../assets/img/prestashop.png") no-repeat center
    center;
  background-size: 90%;
  &.safari {
    left: 40px;
    top: 300px;
    transform: rotate(225deg);
  }
}
.obj-8 {
  animation: move-8 1000ms 100ms forwards;
  background: transparent url("../assets/img/adobecc.svg") no-repeat center
    center;
  background-size: 80%;
  &.safari {
    left: -20px;
    top: 170px;
    transform: rotate(270deg);
  }
}
@media screen and (min-width: 768px) {
  .page.about {
    display: flex;
    justify-content: center;
    align-items: center;
    //height: 90%;
    overflow-x: inherit;
  }
  .blockFlex {
    flex-direction: row;
  }
  .blockInBlockFlex {
    margin: 30px;
    width: calc(50% - 60px);
  }
  .blockInBlock {
    &.blockText {
      margin-bottom: 0;
    }
  }
  .blockImage,
  .iconContainer {
    width: 350px;
    height: 350px;
  }
  .subBlockLeft {
    height: 500px;
  }
  .obj {
    top: 0;
    left: 0;
    offset-path: path("M 0, 175 a 175,175 0 1,1 350,0 a 175,175 0 1,1 -350,0");
  }
}
@media screen and (min-width: 920px) {
  .page.about {
    width: calc(100% - 84px);
  }
  .blockLeft {
    margin-right: 15px;
  }
}
@media screen and (min-width: 1100px) {
  .blockImage,
  .iconContainer {
    width: 400px;
    height: 400px;
  }
  .blockLeft {
    height: auto;
    margin-right: 15px;
  }
  .obj {
    top: 0;
    left: 0;
    width: 3.5rem;
    height: 3.5rem;
    offset-path: path("M 0, 200 a 200,200 0 1,1 400,0 a 200,200 0 1,1 -400,0");
  }
  .obj-2 {
    width: 5.5rem;
    height: 3.5rem;
    background-size: 70%;
  }
  .obj-3 {
    width: 3.9rem;
    height: 3.9rem;
  }
  .obj-4 {
    width: 4.2rem;
    height: 4.2rem;
  }
  .obj-6 {
    width: 4.4rem;
    height: 4.4rem;
  }
}
@media screen and (min-width: 1400px) {
  .page.about {
    //height: 95%;
  }
  .blockImage,
  .iconContainer {
    width: 500px;
    height: 500px;
  }
  .blockImage {
    padding: 80px;
  }
  .blockLeft {
    height: auto;
  }
  .blockInBlock {
    &.blockText {
      padding: 2rem 3rem 0 0;
      .fr {
        font-size: 1.1em;
      }
    }
  }
  .blockInBlockFlex {
    padding: 0 2em;
    margin: 0 30px;
    width: calc(50% - 60px);
  }
  .obj {
    top: 0;
    left: 0;
    width: 4rem;
    height: 4rem;
    offset-path: path("M 0, 250 a 250,250 0 1,1 500,0 a 250,250 0 1,1 -500,0");
  }
  .obj-2 {
    width: 7rem;
    height: 5rem;
    background-size: 70%;
  }
  .obj-3 {
    width: 4.5rem;
    height: 4.5rem;
  }
  .obj-4 {
    width: 5rem;
    height: 5rem;
  }
  .obj-6 {
    width: 5rem;
    height: 5rem;
  }
}
@keyframes move {
  100% {
    offset-distance: 100%;
  }
}
@-webkit-keyframes move {
  0% {
    offset-distance: 0%;
  }
  100% {
    offset-distance: 100%;
  }
}
@keyframes move-1 {
  100% {
    offset-distance: 12.5%;
    opacity: 1;
  }
}
@-webkit-keyframes move-1 {
  0% {
    offset-distance: 0%;
    opacity: 0;
  }
  100% {
    offset-distance: 12.5%;
    opacity: 1;
  }
}
@keyframes move-2 {
  100% {
    offset-distance: 25%;
    opacity: 1;
  }
}
@keyframes move-3 {
  100% {
    offset-distance: 37.5%;
    opacity: 1;
  }
}
@keyframes move-4 {
  100% {
    offset-distance: 50%;
    opacity: 1;
  }
}
@keyframes move-5 {
  100% {
    offset-distance: 62.5%;
    opacity: 1;
  }
}
@keyframes move-6 {
  100% {
    offset-distance: 75%;
    opacity: 1;
  }
}
@keyframes move-7 {
  100% {
    offset-distance: 87.5%;
    opacity: 1;
  }
}
@keyframes move-8 {
  100% {
    offset-distance: 100%;
    opacity: 1;
  }
}
</style>