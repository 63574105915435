<template>
  <div class="simple-form">
    <div class="form-toggle delay"><i class="icon-mail"></i></div>
    <form id="form" class="simform" autocomplete="off">
      <div class="simform-inner">
        <ol class="questions">
          <li>
            <span><label for="q1">Vous êtes ...?</label></span>
            <input id="q1" name="q1" type="text" />
          </li>
          <li>
            <span
              ><label for="q2"
                >Votre adresse e-mail, s'il vous plaît ?</label
              ></span
            >
            <input id="q2" name="q2" type="email" />
          </li>
          <li>
            <span
              ><label for="q3"
                >Numéro de téléphone et/ou identifiant Skype, éventuellement
                ?</label
              ></span
            >
            <input id="q3" name="q3" type="text" />
          </li>
          <li>
            <span
              ><label for="q4"
                >Avez-vous des préférences d'horaires pour vous contacter
                ?</label
              ></span
            >
            <input id="q4" name="q4" type="text" />
          </li>
          <li>
            <span
              ><label for="q5"
                >Si vous avez autres choses... <br />N'hésitez pas !</label
              ></span
            >
            <input id="q5" name="q5" type="text" />
          </li>
        </ol>
        <button class="submit" type="submit">Envoyer</button>
        <div class="controls">
          <button class="next"></button>
          <div class="progress"></div>
          <span class="number">
            <span class="number-current"></span>
            <span class="number-total"></span>
          </span>
          <span class="error-message"></span>
        </div>
      </div>
      <span class="final-message"></span>
    </form>
  </div>
</template>

<script>
export default {
  name: "Contact",
};
</script>