<template>
  <section class="main pageWork">
    <h2><slot name="h2"></slot></h2>
    <h3><slot name="h3"></slot></h3>
    <section>
      <div class="textContainer">
        <div class="contentText">
          <a class="link" :href="url" target="_blank">{{ $t("link.text") }}</a>
          <slot v-if="this.$i18n.locale === 'fr'" name="textFr"></slot>
          <slot v-if="this.$i18n.locale === 'ja'" name="textJp"></slot>
          <p>
            <span class="link">{{ $t("credit.client") }}</span> :
            <a class="link" href="https://www.dedi-agency.com/" target="_blank"
              >Dedi Agency</a
            >
          </p>
          <p>
            <span class="link">{{ $t("credit.roletitle") }}</span
            >{{ $t("credit.role") }}
          </p>
        </div>
      </div>
      <div class="videoContainer">
        <div>
          <video autoplay loop width="100%">
            <slot name="video"></slot>
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
      </div>
    </section>
    <div class="h2Container">
      <title-h-2></title-h-2>
      <h2 :class="{ jp: isJp }">
        <slot name="h2-1"></slot>
      </h2>
    </div>
    <section class="row">
      <div>
        <img
          :src="
            require('../assets/video/' + imageM1 + this.$i18n.locale + '.jpg')
          "
        />
      </div>
      <div>
        <img
          :src="
            require('../assets/video/' + imageM2 + this.$i18n.locale + '.jpg')
          "
        />
      </div>
      <div>
        <img
          :src="
            require('../assets/video/' + imageM3 + this.$i18n.locale + '.jpg')
          "
        />
      </div>
    </section>
    <div class="h2Container">
      <title-h-2></title-h-2>
      <h2 :class="{ jp: isJp }"><slot name="h2-2"></slot></h2>
    </div>
    <section class="rowCenter">
      <div class="videoContainerM">
        <video autoplay loop width="100%">
          <source
            :src="require('../assets/video/' + videoM)"
            type="video/mp4"
          />

          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
      <div class="textContainerM" :class="{ jp: isJp }">
        <div class="contentText">
          <slot v-if="this.$i18n.locale === 'fr'" name="text2Fr"></slot>
          <slot v-if="this.$i18n.locale === 'ja'" name="text2Jp"></slot>
        </div>
      </div>
    </section>
  </section>
</template>
<script>
import TitleH2 from "./TitleH2.vue";
export default {
  components: { TitleH2 },
  name: "WorksFrame",
  props: ["url", "imageM1", "imageM2", "imageM3", "videoM"],
  data() {
    return {};
  },
  computed: {
    isJp: function () {
      if (this.$i18n.locale === "ja") {
        return true;
      } else {
        return false;
      }
    },
    /*topNav: function () {
      var largeur = window.innerWidth;
      var h1height = document.getElementById("h1title").offsetHeight;
      return largeur / 1.7 / 2 - 10 + h1height;
    },*/
  },
};
</script>
<style lang="scss" scoped>
.h2Container {
  position: relative;
  width: 100%;
  /*h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }*/
}
/*.pageWork {
  margin-top: -48px;
}*/
h2.jp {
  font-weight: 500;
}
h3 {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 40px;
  > * {
    margin: 0 8px;
  }
}
@media screen and (min-width: 920px) {
  .h2Container {
    margin-top: 60px;
    margin-bottom: 40px;
  }
}
</style>