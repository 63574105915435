<template>
  <!-- vue-aos data-aos="fade" data-aos-anchor-placement="center-bottom" -->
  <div class="h2bg"></div>
  <!-- /vue-aos -->
</template>
<script>
export default {
  name: "TitleH2",
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.h2bg {
  position: absolute;
  top: 50%;
  background: url("../assets/img/line.png") no-repeat center center;
  background-size: 80%;
  padding-bottom: 1rem;
  width: 100%;
  height: 100%;
  z-index: -1;
}
@media screen and (min-width: 768px) {
  .h2bg {
    background-size: 50%;
  }
}
@media screen and (min-width: 1200px) {
  .h2bg {
    background-size: 40%;
  }
}
[data-aos="new-animation"] {
  opacity: 0;
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
  }

  @media screen and (min-width: 768px) {
    transform: translateX(100px);

    &.aos-animate {
      transform: translateX(0);
    }
  }
}
</style>