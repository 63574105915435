<template>
  <works-frame-logo :articleID="articleID">
    <template #h2>Euro Japan Crossing</template>
    <template #h3> <span>#web design</span><span>#front-end</span></template>
    <template #sub
      >Agence de conseil en développement d'affaire en europe</template
    >
    <template #date>2016</template>
    <template #intro>
      EJC est spécialisé dans les domaines des produits traditionnels et design
      japonais, de la culture japonaise. L'agence anime des salons, organise des
      concours et édite également une presse gratuite consacrée aux spécialités
      japonaises.
    </template>
    <template #imageTop>
      <img src="@/assets/img/works/ejc_m.jpg" />
    </template>
    <template #image2><img src="@/assets/img/works/ejc_web.jpg" /></template>
  </works-frame-logo>
</template>
<script>
import WorksFrameLogo from "./WorksFrameLogo.vue";
export default {
  components: { WorksFrameLogo },
  name: "WorkEJC",
  data() {
    return {
      articleID: 9,
    };
  },
  mounted() {
    this.$emit("emitID", this.articleID);
  },
};
</script>