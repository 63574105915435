var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"100%","height":"100%","viewBox":"0 0 1000 2000"}},[_c('defs',[_c('g',{attrs:{"id":"wave-1"}},[_c('path',{attrs:{"d":_vm.pathStrF,"fill":"url(#g1)"}})]),_c('g',{attrs:{"id":"wave-2"}},[_c('path',{attrs:{"d":_vm.pathStrS,"fill":"url(#g2)"}})]),_c('linearGradient',{attrs:{"id":"g1","x1":"0%","y1":"50%"}},[_c('stop',{attrs:{"offset":"0","stop-color":_vm.color0b}},[_c('animate',{attrs:{"attributeName":"stop-color","values":_vm.color0b +
              ';' +
              _vm.color1b +
              ';' +
              _vm.color2b +
              ';' +
              _vm.color3b +
              ';' +
              _vm.color0b,"dur":"8s","repeatCount":"indefinite"}})]),_c('stop',{attrs:{"offset":"0.2","stop-color":_vm.color1b}},[_c('animate',{attrs:{"attributeName":"stop-color","values":_vm.color1b + ';' + _vm.color2b + ';' + _vm.color3b + ';' + _vm.color1b,"dur":"8s","repeatCount":"indefinite"}})]),_c('stop',{attrs:{"offset":"0.4","stop-color":_vm.color2b}},[_c('animate',{attrs:{"attributeName":"stop-color","values":_vm.color2b + ';' + _vm.color3b + ';' + _vm.color1b + ';' + _vm.color2b,"dur":"8s","repeatCount":"indefinite"}})]),_c('stop',{attrs:{"offset":"0.6","stop-color":_vm.color3b}},[_c('animate',{attrs:{"attributeName":"stop-color","values":_vm.color3b + ';' + _vm.color1b + ';' + _vm.color2b + ';' + _vm.color3b,"dur":"8s","repeatCount":"indefinite"}})]),_c('stop',{attrs:{"offset":"0.8","stop-color":_vm.color3b}},[_c('animate',{attrs:{"attributeName":"stop-color","values":_vm.color3b + ';' + _vm.color1b + ';' + _vm.color2b + ';' + _vm.color3b,"dur":"8s","repeatCount":"indefinite"}})]),_c('stop',{attrs:{"offset":"1","stop-color":_vm.color0b}},[_c('animate',{attrs:{"attributeName":"stop-color","values":_vm.color0b +
              ';' +
              _vm.color1b +
              ';' +
              _vm.color2b +
              ';' +
              _vm.color3b +
              ';' +
              _vm.color0b,"dur":"8s","repeatCount":"indefinite"}})])],1),_c('linearGradient',{attrs:{"id":"g2","x1":"0%","y1":"50%"}},[_c('stop',{attrs:{"offset":"0.2","stop-color":_vm.color1}},[_c('animate',{attrs:{"attributeName":"stop-color","values":_vm.color1 + ';' + _vm.color2 + ';' + _vm.color3 + ';' + _vm.color1,"dur":"8s","repeatCount":"indefinite"}})]),_c('stop',{attrs:{"offset":"0.4","stop-color":_vm.color2}},[_c('animate',{attrs:{"attributeName":"stop-color","values":_vm.color2 +
              ';' +
              _vm.color3 +
              ';' +
              _vm.color0 +
              ';' +
              _vm.color1 +
              ';' +
              _vm.color2,"dur":"8s","repeatCount":"indefinite"}})]),_c('stop',{attrs:{"offset":"0.6","stop-color":_vm.color3}},[_c('animate',{attrs:{"attributeName":"stop-color","values":_vm.color3 + ';' + _vm.color1 + ';' + _vm.color2 + ';' + _vm.color3,"dur":"8s","repeatCount":"indefinite"}})]),_c('stop',{attrs:{"offset":"0.8","stop-color":_vm.color3}},[_c('animate',{attrs:{"attributeName":"stop-color","values":_vm.color3 + ';' + _vm.color1 + ';' + _vm.color2 + ';' + _vm.color3,"dur":"8s","repeatCount":"indefinite"}})])],1)],1),_c('use',{attrs:{"xlink:href":"#wave-2","transform":'translate(0 ' + _vm.wave1Y + ')'}}),_c('use',{attrs:{"xlink:href":"#wave-1","transform":'translate(0 ' + _vm.wave2Y + ')'}}),_c('use',{attrs:{"xlink:href":"#wave-2","transform":'translate(0 ' + _vm.wave3Y + ')'}}),_c('use',{attrs:{"xlink:href":"#wave-1","transform":'translate(0 ' + _vm.wave4Y + ')'}}),_c('use',{attrs:{"xlink:href":"#wave-2","transform":'translate(0 ' + _vm.wave5Y + ')'}})])])}
var staticRenderFns = []

export { render, staticRenderFns }