<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 1000 2000"
    >
      <defs>
        <g id="wave-1">
          <path :d="pathStrF" fill="url(#g1)" />
        </g>
        <g id="wave-2">
          <path :d="pathStrS" fill="url(#g2)" />
        </g>
        <linearGradient id="g1" x1="0%" y1="50%">
          <stop offset="0" :stop-color="color0b">
            <animate
              attributeName="stop-color"
              :values="
                color0b +
                ';' +
                color1b +
                ';' +
                color2b +
                ';' +
                color3b +
                ';' +
                color0b
              "
              dur="8s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="0.2" :stop-color="color1b">
            <animate
              attributeName="stop-color"
              :values="color1b + ';' + color2b + ';' + color3b + ';' + color1b"
              dur="8s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="0.4" :stop-color="color2b">
            <animate
              attributeName="stop-color"
              :values="color2b + ';' + color3b + ';' + color1b + ';' + color2b"
              dur="8s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="0.6" :stop-color="color3b">
            <animate
              attributeName="stop-color"
              :values="color3b + ';' + color1b + ';' + color2b + ';' + color3b"
              dur="8s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="0.8" :stop-color="color3b">
            <animate
              attributeName="stop-color"
              :values="color3b + ';' + color1b + ';' + color2b + ';' + color3b"
              dur="8s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="1" :stop-color="color0b">
            <animate
              attributeName="stop-color"
              :values="
                color0b +
                ';' +
                color1b +
                ';' +
                color2b +
                ';' +
                color3b +
                ';' +
                color0b
              "
              dur="8s"
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
        <linearGradient id="g2" x1="0%" y1="50%">
          <stop offset="0.2" :stop-color="color1">
            <animate
              attributeName="stop-color"
              :values="color1 + ';' + color2 + ';' + color3 + ';' + color1"
              dur="8s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="0.4" :stop-color="color2">
            <animate
              attributeName="stop-color"
              :values="
                color2 +
                ';' +
                color3 +
                ';' +
                color0 +
                ';' +
                color1 +
                ';' +
                color2
              "
              dur="8s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="0.6" :stop-color="color3">
            <animate
              attributeName="stop-color"
              :values="color3 + ';' + color1 + ';' + color2 + ';' + color3"
              dur="8s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="0.8" :stop-color="color3">
            <animate
              attributeName="stop-color"
              :values="color3 + ';' + color1 + ';' + color2 + ';' + color3"
              dur="8s"
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
      </defs>
      <use xlink:href="#wave-2" :transform="'translate(0 ' + wave1Y + ')'" />
      <use xlink:href="#wave-1" :transform="'translate(0 ' + wave2Y + ')'" />
      <use xlink:href="#wave-2" :transform="'translate(0 ' + wave3Y + ')'" />
      <use xlink:href="#wave-1" :transform="'translate(0 ' + wave4Y + ')'" />
      <use xlink:href="#wave-2" :transform="'translate(0 ' + wave5Y + ')'" />
    </svg>
  </div>
</template>

<script>
export default {
  name: "OneWave",
  data() {
    return {
      //values: [], // Y座標の配列
      time: 0,
      ww: window.innerWidth,
      wh: window.innerHeight,
      color0: "#dcfd84",
      color1: "#8eeeee" /*cdff98 */,
      color2: "#aaf5fa",
      color3: "#f6d0fb" /*e5fc68 */,
      color0b: "#e9ff93",
      color1b: "#ffb0b6" /*ffd3bb*/,
      color2b: "#ffd4bd" /*80d6cd*/,
      color3b: "#ff6b9f" /*ff6b9f ffa2c3*/,
    };
  },
  computed: {
    rate: function () {
      return this.ww / this.wh;
    },
    wave1Y: function () {
      if (this.rate < 0.5) {
        return 10;
      } else if (this.rate < 1) {
        return -130;
      } else if (this.rate > 1.5) {
        return -350;
      } else {
        return -400;
      }
    },
    wave2Y: function () {
      if (this.rate < 0.5) {
        return 400;
      } else if (this.rate < 1) {
        return 160;
      } else if (this.rate > 1.5) {
        return -200;
      } else {
        return -100;
      }
    },
    wave3Y: function () {
      if (this.rate < 0.5) {
        return 700;
      } else if (this.rate < 1) {
        return 450;
      } else if (this.rate > 1.5) {
        return -50;
      } else {
        return 200;
      }
    },
    wave4Y: function () {
      if (this.rate < 0.5) {
        return 1000;
      } else if (this.rate < 1) {
        return 650;
      } else if (this.rate > 1.5) {
        return 100;
      } else {
        return 500;
      }
    },
    wave5Y: function () {
      if (this.rate < 0.5) {
        return 1300;
      } else if (this.rate < 1) {
        return 850;
      } else if (this.rate > 1.5) {
        return 250;
      } else {
        return 800;
      }
    },
    values() {
      return new Array(POINTS_COUNT).fill(0).map((_, index) => {
        const x = index / POINTS_COUNT; // x座標(0-1)
        const yBase = index % 2 === 0 ? 1 : -1; // y座標(-1と1を交互に設定)
        const y = Math.sin(x / WAVE_SCALE - this.time * SPEED) * yBase; // sin波を掛ける
        return y;
      });
    },
    values2() {
      return new Array(POINTS_COUNT2).fill(0).map((_, index) => {
        const x = index / POINTS_COUNT2; // x座標(0-1)
        const yBase = index % 2 === 0 ? 1 : -1; // y座標(-1と1を交互に設定)
        const y = Math.cos(x / WAVE_SCALE2 - this.time * SPEED2) * yBase; // sin波を掛ける
        return y;
      });
    },
    pathStr() {
      // 座標の配列からパスの文字列（d属性）を生成して返す関数（後述）
      return valuesToPathStr(this.values);
    },
    pathStr1() {
      return this.pathStr.split(" ").shift().split(",").pop();
    },
    pathStrF() {
      return valuesToPathStr(this.values) + " V 600 H 0 L0 0";
      //return this.pathStr.split(" ") + " V 400 H 0 L0 " + this.pathStr1;
    },
    pathStrS() {
      return valuesToPathStr(this.values2) + " V 600 H 0 L0 0";
      //return this.pathStr.split(" ") + " V 400 H 0 L0 " + this.pathStr1;
    },
  },
  methods: {
    next() {
      // ランダムなY座標の配列を新しく生成する関数（コードは省略）
      this.values = generateValues();
    },
  },
  mounted() {
    //this.next();
    //window.setInterval(this.next, 2000); // 2秒ごとに新しい座標配列を作る
    // requestAnimationFrameを使い一コマごとに時間($data.time)を更新
    const startTime = Date.now();
    const update = () => {
      this.time = Date.now() - startTime;
      requestAnimationFrame(update);
    };
    update();
  },
};
const POINTS_COUNT = 10; // 座標点の数
const POINTS_COUNT2 = 10; // 座標点の数
const MAX_Y = 30; // 山の最大値
const WIDTH = 1000; // 全体の幅
const HEIGHT = 500; // 全体の高さ
const EASE = 0.5; // コーナーの曲がり具合(0.0 - 0.5)
const SPEED = 0.0005;
const SPEED2 = 0.0007;
const WAVE_SCALE = (1 / Math.PI) * 0.5;
const WAVE_SCALE2 = (1 / Math.PI) * 0.3;

/**
 * 0〜1と-1〜0の乱数で交互に埋めた値配列を生成します
 */
const generateValues = () => {
  return new Array(POINTS_COUNT + 1)
    .fill(0)
    .map((_, index) => Math.random() * (index % 2 ? 1 : -1));
};

/**
 * 値配列を元にパス（ベジェ曲線）を描画するための文字列を生成して返します
 * @values {Number[]} Y座標(-1.0〜+1.0)の配列
 * @return String パス文字列
 */
const valuesToPathStr = (values) => {
  if (!values.length) {
    return "M0 0";
  }
  const points = values.map((y, x) => ({
    x: (x / (POINTS_COUNT - 1)) * WIDTH,
    y: y * MAX_Y + HEIGHT / 2,
  }));
  const p0 = points.shift();
  const controlX = (WIDTH / (POINTS_COUNT - 1)) * EASE;
  // 全ての座標に制御点を追加しながら曲線（三次ベジェ曲線）を描画するための文字列を生成
  return (
    `M${p0.x} ${p0.y} S` +
    points.map((p) => `${p.x - controlX} ${p.y} ${p.x} ${p.y}`).join(" ")
  );
};
</script>