import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/work',
    name: 'Work',
    component: () => import('../views/WorksTop.vue'),
    children: [
      {
        path: '',
        name: 'WorksHome',
        component: () => import('../components/WorksHome.vue')
      },
      {
        path: 'boutique-du-bois',
        name: 'Work1',
        component: () => import('../components/Work1.vue')
      },
      {
        path: 'ponsard-et-dumas',
        name: 'Work2',
        component: () => import('../components/Work2.vue')
      },
      {
        path: 'maisons-terre-et-pierres',
        name: 'WorkMTP',
        component: () => import('../components/WorkMTP.vue')
      },
      {
        path: 'colexo',
        name: 'WorkColexo',
        component: () => import('../components/WorkColexo.vue')
      },
      {
        path: 'alain-gousse',
        name: 'WorkAG',
        component: () => import('../components/WorkAG.vue')
      },
      {
        path: 'inedito',
        name: 'WorkInedito',
        component: () => import('../components/WorkInedito.vue')
      },
      {
        path: 'primo-house',
        name: 'WorkPH',
        component: () => import('../components/WorkPH.vue')
      },
      {
        path: 'vin',
        name: 'WorkWine',
        component: () => import('../components/WorkWine.vue')
      },
      {
        path: 'ejccrossing',
        name: 'WorkEJC',
        component: () => import('../components/WorkEJC.vue')
      },
    ]
  },
  {
    path: '/pro',
    name: 'Pro',
    component: () => import('../views/Pro.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/legalmentions',
    name: 'Legal',
    component: () => import('../views/Legal.vue')
  },
  /*{
    path: '/work/:Pid',
    name: 'Details',
    component: () => import('../components/Details.vue')
  }*/
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      const position = {};
      if (to.hash) {
        position.selector = to.hash;
        if (document.querySelector(to.hash)) {
          return position;
        }
      }
      return false
    }
  },
  /*scrollBehavior: async (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }

    const findEl = async (hash, x) => {
      return document.querySelector(hash) ||
        new Promise((resolve) => {
          if (x > 50) {
            return resolve();
          }
          setTimeout(() => {
            resolve(findEl(hash, ++x || 1));
          }, 100);
        });
    }

    if (to.hash) {
      const el = await findEl(to.hash);

      return window.scrollTo({ top: el.offsetTop, behavior: 'smooth' });
    }

    return { x: 0, y: 0 };
  },*/
  routes
})

export default router
