<template>
  <section id="list">
    <!-- article
      v-for="(item, index) in articleImage"
      :key="index"
      :style="{
        backgroundImage:
          'url(~@/assets/img/works/' + articleImage[index] + '.jpg)',
        backgroundSize: '130%',
      }"
    ></article -->
    <article
      v-for="(item, index) in 9"
      :key="index"
      :class="'a' + (index + 1)"
      @click="emitID(index + 1)"
    >
      <router-link :to="'/work/' + link[index]" class="overlay">
        <span class="title">{{ articleTitle[index] }}</span>
        <span class="tag">{{ articleTag[index] }}</span></router-link
      >
    </article>
  </section>
</template>

<script>
export default {
  data() {
    return {
      articleID: 0,
      link: [
        "boutique-du-bois",
        "ponsard-et-dumas",
        "maisons-terre-et-pierres",
        "colexo",
        "alain-gousse",
        "inedito",
        "primo-house",
        "vin",
        "ejccrossing",
      ],
      articleImage: ["inedito_carte", "mtp_logo"],
      articleTitle: [
        "La Boutique du Bois",
        "Ponnsard et Dumas",
        "Maison Terre et Pierres",
        "Colexo",
        "Alain Gousse",
        "Inedito",
        "Primo House",
        "Vin de Pays d'Oc",
        "Euro Japan Crossing",
      ],
      articleTag: [
        "#front-end",
        "#front-end",
        "#front-end #web design #logo #print",
        "#front-end #web design",
        "#front-end #web design #logo",
        "#logo #print",
        "#front-end #web design #logo #print",
        "#print",
        "#front-end #web design",
      ],
    };
  },
  mounted() {
    this.$emit("emitID", this.articleID);
  },
  methods: {
    setProdIdWork(prodId) {
      this.$emit("setProdWork", prodId);
    },
    emitID(id) {
      this.$emit("emit-id", id);
    },
  },
};
</script>
<style lang="scss">
#list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
article {
  width: calc(33.33% - 10px);
  padding-top: calc(33.33% - 10px);
  height: auto;
  margin: 5px;
  background: black;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  .overlay {
    //display: none;
    display: flex;
    flex-direction: column;
    opacity: 0;
    background-color: rgba(black, 0.75);
    color: white;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    transition: all 0.3s;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    .title {
      font-size: 20px;
      margin-bottom: 10px;
    }
    .tag {
      font-size: 16px;
      opacity: 0.7;
    }
  }
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 6px 10px 2px #ebebeb;
    .overlay {
      opacity: 1;
    }
  }
}
.a6 {
  background: url("~@/assets/img/works/inedito_carte.jpg") no-repeat center
    center;
  background-size: 130%;
}
.a9 {
  background: url("~@/assets/img/works/ejc_web.jpg") no-repeat center center;
  background-size: 150%;
}
.a3 {
  background: url("~@/assets/img/works/mtp_logo.jpg") no-repeat center center;
  background-size: 135%;
}
.a4 {
  background: url("~@/assets/img/works/colexo_mac.jpg") no-repeat center center;
  background-size: 135%;
}
.a5 {
  background: url("~@/assets/img/works/ag_logo.jpg") no-repeat center center;
  background-size: 150%;
}
.a1 {
  background: url("~@/assets/img/works/bdb_mac.jpg") no-repeat center center;
  background-size: 135%;
}
.a7 {
  background: url("~@/assets/img/works/ph_paper.jpg") no-repeat center center;
  background-size: 130%;
}
.a8 {
  background: url("~@/assets/img/works/wine-1.jpg") no-repeat center center;
  background-size: 140%;
}
.a2 {
  background: url("~@/assets/img/works/pd_mac.jpg") no-repeat center center;
  background-size: 135%;
}
@media screen and (min-width: 536px) {
  header nav > * {
    margin: 10px;
    font-size: 14px;
  }
  h1 {
    margin: 0 30px 0 15px;
  }
  article {
    width: calc(33.33% - 30px);
    padding-top: calc(33.33% - 30px);
    margin: 15px;
  }
  #navSub {
    padding: 0 15px;
  }
}
@media screen and (min-width: 920px) {
  #list {
    justify-content: flex-start;
  }
  article {
    width: calc(31% - 30px);
    padding-top: calc(31% - 30px);
  }
}
</style>