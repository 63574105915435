<template>
  <works-frame-logo :articleID="articleID">
    <template #h2>INEDITO</template>
    <template #h3> <span>#logo</span><span>#print</span> </template>
    <template #sub>Concept store et galerie d'art contemporain</template>
    <template #date>2007</template>
    <template #intro>
      INEDITO est un lieu insolite, où on trouve des objets recyclés et
      détournés. Sa devise : L’art transforme l’inutilie en utile.
    </template>
    <template #imageTop>
      <img src="@/assets/img/works/inedito_signboard.jpg" />
    </template>
    <template #image2
      ><img src="@/assets/img/works/inedito_carte_l.jpg"
    /></template>
    <template #image3
      ><img src="@/assets/img/works/inedito_print.jpg"
    /></template>
  </works-frame-logo>
</template>
<script>
import WorksFrameLogo from "./WorksFrameLogo.vue";
export default {
  components: { WorksFrameLogo },
  name: "WorkInedito",
  data() {
    return {
      articleID: 6,
    };
  },
  mounted() {
    this.$emit("emitID", this.articleID);
  },
};
</script>