<template>
  <div>
    <BgMotif />
    <div class="mentions">
      <h1>Mentions légales</h1>
      <div>
        <h2>Propriétaire et Responsable de publication</h2>

        <p>Emiko Muraoka</p>

        <p>
          81B rue Laennec 69008 LYON<br />
          <a href="mailto:contact@3emi.com">contact@3emi.com</a>
        </p>

        <p>N° SIRET : 500 792 072 00018</p>
      </div>
      <div>
        <h2>Webmaster</h2>

        <p>Emiko MURAOKA</p>
      </div>
      <div>
        <h2>Hébergeur</h2>

        <p><strong>OVH</strong> - 2 rue Kellermann 59100 Roubaix</p>
      </div>
      <div>
        <h2>Propriété intellectuelle</h2>

        <p>
          Les créations réalisées par Emiko Muraoka sont protégées au titre du
          droit d’auteur conformément aux dispositions des livres I à III du
          Code de la Propriété Intellectuelle. A ce titre, toute reproduction,
          représentation, utilisation, adaptation, modification, incorporation,
          traduction, commercialisation, partielles ou intégrales des éléments
          contenus dans ce site, sans l’autorisation écrite préalable de Emiko
          Muraoka est interdite, sous peine de constituer un délit de
          contrefaçon de droit d’auteur.
        </p>
      </div>
      <div>
        <h2>Données personnelles</h2>

        <p>
          3emi.com ne collecte pas d'informations personnelles relatives à
          l’utilisateur. Le site n'utilise pas Google Analytics ou tout autre
          service qui est suseptible d'utiliser des cookies.<br />
          L'utilisateur peut contacter le propriétaire du site par les
          coordonnées figurant sur le site.<br />Le site n’est pas déclaré à la
          CNIL car il ne recueille pas d’informations personnelles.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
//import WaveSingle from "../components/WaveSingle.vue";
import BgMotif from "../components/BgMotif.vue";
export default {
  name: "Legal",
  components: {
    //WaveSingle,
    BgMotif,
  },
};
</script>
<style lang="scss" scoped>
.mentions {
  text-align: left;
  margin: 2% 7% 70px;
  padding: 1% 3%;
  background-color: rgba(255, 255, 255, 0.8);
  //border: 1px solid rgb(228, 228, 228);
  box-shadow: 0px 0px 20px 0px rgb(230, 230, 230);
}
h1 {
  text-transform: uppercase;
  font-size: 1.8em;
}
h2 {
  line-height: 1.2;
}
a {
  color: $linkcolor;
  transition: all 0.3s;
  padding: 0 10px;
  border-radius: 5px;
  &:hover {
    background-color: $linkcolor;
    color: white;
    text-decoration: none;
  }
}
@media screen and (min-width: 920px) {
  .mentions {
    margin: 2% 7%;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    line-height: inherit;
  }
}
</style>