<template>
  <div id="app">
    <Nav @change-locale="changeLocale" />
    <!-- header v-if="selectedProd == 0">
      <h1>
        <router-link to="/work">WORKS {{ prodId }}</router-link>
      </h1>
      <nav id="navSub">
        <button class="prev" :disabled="prodId == 1" @click="prev"></button>
        <button class="next" :disabled="prodId == 9" @click="next"></button>
      </nav>
    </header -->
    <transition>
      <router-view
        @setProdIdWork="setProdIdWork"
        @change-locale="changeLocale"
        :prodId="selectedProd"
    /></transition>
  </div>
</template>
<script>
import Nav from "@/components/Nav.vue";
import AOS from "aos";
export default {
  data() {
    return {
      selectedProd: 0,
      lang: "fr",
    };
  },
  components: {
    Nav,
  },
  created() {
    AOS.init();
  },
  methods: {
    setProdId(prodId) {
      this.selectedProd = prodId;
    },
    setProdIdWork(prodId) {
      this.selectedProd = prodId;
    },
    changeLocale() {
      this.$i18n.locale = this.$i18n.locale === "ja" ? "fr" : "ja";
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.v-enter {
  transform: translate(-100px, 0);
  opacity: 0;
}
.v-enter-to {
  opacity: 1;
}
.v-enter-active {
  transition: all 1s 0s ease;
}
.v-leave {
  transform: translate(0, 0);
  opacity: 1;
}
.v-leave-to {
  transform: translate(100px, 0);
  opacity: 0;
}
.v-leave-active {
  transition: all 0.5s 0s ease;
}
</style>
