<template>
  <works-frame
    url="https://www.ponsard-dumas.com/"
    imageM1="pd_m_1_"
    imageM2="pd_m_2_"
    imageM3="pd_m_3_"
    videoM="pd_m_edit_1000.mp4"
  >
    <template #h2>Ponsard et Dumas</template>
    <template #h3><span>#front-end</span></template>
    <template #textFr>
      <p>
        Ponsard et Dumas est une société spécialisée dans la confection de robes
        d’avocat et plus largement de robes de cérémonie pour différents
        secteurs (justice, religions, enseignements, etc.)
      </p>
      <p>
        Pour proposer des tenues officielles sur-mesure, il a été développé un
        configurateur sur-mesure.
      </p>
      <p>
        L'idée est de créer des volets, personnalisant ainsi le produit étape
        par étape, en laissant la possibilité de revenir en arrière.
      </p>
      <ul>
        <li>Conception ergonomique et fonctionnelle</li>
        <li>Direction artistique et web design</li>
        <li>Stratégie e-marketing et référencement naturel</li>
        <li>Intégration html/css responsive</li>
        <li>Développement et intégration sur mesure Shop by Dedi</li>
      </ul>
    </template>
    <template #textJp>
      <p>
        この会社は、司法・大学・教育機関及び宗教機関関係者の制服である法服の販売をしています。
      </p>
      <p>
        「法服をカスタマイズしてオンラインで購入する」事を目的とし、そこに到るまでのサイトの構成・ナビゲーション・デザインの見直しと、服をカスタマイズするモジュールの開発を行いました。
      </p>

      <ul>
        <li><span>UI/UX設計</span></li>
        <li><span>アートディレクション</span></li>
        <li><span>サイトデザイン</span></li>
        <li><span>フロントエンド開発</span></li>
        <li><span>バックエンド開発</span></li>
        <li><span>カスタムモジュール開発</span></li>
      </ul>
    </template>
    <template #video>
      <source src="../assets/video/ponsardetdumas.mp4" type="video/mp4" />
    </template>
    <template #h2-1>{{ $t("work2.title1") }}</template>
    <template #h2-2>{{ $t("work2.title2") }}</template>
    <template #text2Fr>
      <p>
        Avec le configurateur, on choisit comme en boutique ; le tissu et la
        couleur de doublure, le texte pour broderie intérieure, les options
        pratiques telle que la fermeture aimantée, etc.
      </p>
      <p>
        On peut vérifier le résultat avec un aperçu, et ajouter des options en
        gardant un oeil sur la somme totale.
      </p>
      <p>
        Il faut bien mesurer les tailles comme indiquer, et le tour est joué !
      </p>
    </template>
    <template #text2Jp>
      <p>裏地、刺繍、袖口等、お店で作る様に順に細かく設定して行きます。</p>
      <p>
        出来上がりイメージを確認しながら、刺繍で縫い付ける言葉を変更したり、合計金額を確認しながらオプションを追加したり。
      </p>
      <p>最後は寸法を測って、出来上がりです。</p>
    </template>
  </works-frame>
</template>

<script>
import WorksFrame from "./WorksFrame.vue";
export default {
  components: { WorksFrame },
  name: "Work2",
  data() {
    return {
      articleID: 2,
    };
  },
  mounted() {
    this.$emit("emitID", this.articleID);
  },
};
</script>