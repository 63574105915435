<template>
  <div class="pageWork">
    <section class="first">
      <div class="textContainer">
        <div class="contentText">
          <h2>Les enfants du design</h2>
          <p>
            <a
              class="link"
              href="https://www.lesenfantsdudesign.com/"
              target="_blank"
              >サイトを見る</a
            >
          </p>

          <p>
            このサイトは、子供向けのデザイン家具・雑貨のオンラインショップです。
            商品のセレクトもさることながら、「子供向け」であることを前面に出す事を目的とした繊細なデザインと、オンラインショップに置けるユーザビィティーを重視したナビゲーションで高い成果を上げています。
          </p>

          <p>
            またこのサイトは、リヨン商工会議所主催のLyon Shop
            WebDesignコンクールにおいて大賞を受賞しました。
          </p>

          <ul>
            <li><span>UI/UX設計</span></li>
            <li><span>フロントエンド開発</span></li>
            <li><span>バックエンド開発</span></li>
            <li><span>カスタムモジュール開発</span></li>
          </ul>
          <p>
            <span class="link">請負元：</span
            ><a class="link" href="https://www.dedi-agency.com" target="_blank"
              >Dedi Agency</a
            >
          </p>

          <p><span class="link">担当：</span>フロントエンド開発</p>
        </div>
      </div>
      <div class="videoContainer">
        <img
          src="../assets/video/ledd.jpg"
          srcset="
            ../assets/video/ledd_640.jpg  320w,
            ../assets/video/ledd_1000.jpg 500w,
            ../assets/video/ledd_1400.jpg 700w,
            ../assets/video/ledd.jpg      960w
          "
        />
      </div>
    </section>
    <section>
      <div class="textContainer">
        <div class="contentText">
          <h2>Résistex</h2>
          <p>
            <a class="link" href="https://www.resistex-sa.com" target="_blank"
              >サイトを見る</a
            >
          </p>

          <p>
            この会社は、エコロジーのコンセプトに基づいた照明設計をBtoBで提案しています。
          </p>

          <p>
            お客様が求めている商品を最短で見つける設計と、お客様が見積もりを通して営業にコンタクトを取る事をゴールとしました。<br />
            また、どの様にお客様を誘導すれば良いのかを測るため、事前に顧客調査及びアンケートも行いました。
          </p>
          <ul>
            <li><span>アクセス解析</span></li>
            <li><span>顧客データ解析</span></li>
            <li><span>デジタルマーケティング</span></li>
            <li><span>競合ベンチマーク</span></li>
            <li><span>目標ベンチマーク</span></li>
            <li><span>UI/UX設計</span></li>
            <li><span>VI（ビジュアル・アイデンティティー）制作</span></li>
            <li><span>アートディレクション</span></li>
            <li><span>サイトデザイン</span></li>
            <li><span>フロントエンド開発</span></li>
            <li><span>バックエンド開発</span></li>
          </ul>
          <p>
            請負元：<a href="https://www.dedi-agency.com" target="_blank"
              >Dedi Agency</a
            >
          </p>

          <p>担当：フロントエンド開発</p>
        </div>
      </div>
      <div class="videoContainer">
        <img
          src="../assets/video/resistex.jpg"
          srcset="
            ../assets/video/resistex_640.jpg  320w,
            ../assets/video/resistex_1000.jpg 500w,
            ../assets/video/resistex_1400.jpg 700w,
            ../assets/video/resistex.jpg      960w
          "
        />
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "Pro",
};
</script>
<style lang="scss" scoped>
.pageWork {
  section {
    margin-top: 10px;
    margin-bottom: 40px;
    flex-direction: column;
    .textContainer {
      width: 100%;
      order: 1;
      font-size: 0.9em;
    }
    .videoContainer {
      width: 100%;
      margin-bottom: 0;
    }
    &.first {
      position: relative;
      padding-bottom: 20px;
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 50%;
        height: 3px;
        background-color: rgb(245, 245, 245);
      }
    }
    h2 {
      margin-top: 1em;
      margin-bottom: 1em;
      + p {
        margin-bottom: 1.8em;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .pageWork {
    section {
      .textContainer {
        font-size: 1em;
      }
    }
  }
}
@media screen and (min-width: 920px) {
  .pageWork {
    padding-left: 2em;
    padding-top: 1.5rem;
    section {
      margin-bottom: 80px;
      &.first {
        padding-bottom: 50px;
      }
    }
  }
}

@media screen and (min-width: 1100px) {
  .pageWork {
    padding-left: 4em;
    padding-top: 3rem;
    section {
      align-items: center;
      flex-direction: row;
      .textContainer {
        width: calc(40% - 30px);
        order: 0;
      }
      .videoContainer {
        width: 60%;
        margin-bottom: 0;
      }
      &.first {
        padding-bottom: 100px;
      }
      h2 {
        margin-top: 0;
      }
    }
  }
}
@media screen and (min-width: 1440px) {
  .pageWork {
    section {
      margin-top: 30px;
      .textContainer {
        width: calc(30% - 30px);
      }
      .textContainer.right {
        padding-right: 0;
        padding-left: 30px;
      }
      .videoContainer {
        width: 70%;
        margin-bottom: 0;
      }
    }
  }
}
</style>