<template>
  <div>
    <div class="knockout">
      <svg class="knockout-text-container" width="100%" height="100%">
        <defs>
          <pattern
            id="hisigata"
            x="0.1"
            y="0.1"
            width="0.01"
            height="0.03"
            patternUnits="objectBoundingBox"
          >
            <path
              fill="#fff"
              d="M5,1.118L9.441,10L5,18.882L0.559,10L5,1.118 M5,0L0,10l5,10l5-10L5,0L5,0z"
            />
          </pattern>
        </defs>
        <rect
          class="knockout-text-bg"
          width="100%"
          height="100%"
          fill="#fff"
          x="0"
          y="0"
          fill-opacity="1"
          mask="url(#knockout-text)"
        />
        <rect
          width="100%"
          height="100%"
          fill="url(#hisigata)"
          x="0"
          y="0"
          fill-opacity="1"
        />

        <mask id="knockout-text">
          <rect width="100%" height="100%" fill="#fff" x="0" y="0" />
          <text
            x="1%"
            :y="val1 + '%'"
            fill="#000"
            :style="{ fontSize: homeFontSize + 'em' }"
          >
            <tspan>Front-End</tspan>
          </text>
          <text
            x="1%"
            :y="val2 + '%'"
            fill="#000"
            :style="{ fontSize: homeFontSize + 'em' }"
          >
            <tspan>Developer</tspan>
          </text>
          <text
            x="2%"
            :y="val3 + '%'"
            fill="#000"
            :style="{ fontSize: homeFontSize + 'em' }"
          >
            <tspan>Web</tspan>
          </text>
          <text
            x="1%"
            :y="val4 + '%'"
            fill="#000"
            :style="{ fontSize: homeFontSize + 'em' }"
          >
            <tspan>Designer</tspan>
          </text>
        </mask>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeText",
  data() {
    return {
      ww: window.innerWidth,
      wh: window.innerHeight,
    };
  },
  computed: {
    homeFontSize: function () {
      if (this.ww / 100 > 12) {
        return 12;
      } else {
        return this.ww / 100;
      }
    },
    rate: function () {
      return this.ww / this.wh;
    },
    val1: function () {
      /*return this.homeFontSize * 1.8;*/
      if (this.rate > 1) {
        return 24;
      } else {
        return 20;
      }
    },
    val2: function () {
      /*return this.homeFontSize * 3.6;*/
      if (this.rate > 1) {
        return 46;
      } else {
        return 40;
      }
    },
    val3: function () {
      /*return this.homeFontSize * 5.4;*/
      if (this.rate > 1) {
        return 68;
      } else {
        return 60;
      }
    },
    val4: function () {
      /*if (this.ww / 100 > 8) {
        return this.homeFontSize * 7.2;
      } else {
        return this.homeFontSize * 7.6;
      }*/
      if (this.rate > 1) {
        return 90;
      } else {
        return 80;
      }
    },
  },
  methods: {
    handleResize() {
      this.ww = window.innerWidth;
      this.$nextTick(() => {
        this.drawText();
      });
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
};
</script>