<template>
  <works-frame
    url="https://www.laboutiquedubois.com/"
    imageM1="bb_m_2_"
    imageM2="bb_m_3_"
    imageM3="bb_m_4_"
    videoM="bb_m_1.mp4"
  >
    <template #h2>La Boutique du Bois</template>
    <template #h3><span>#front-end</span></template>
    <template #textFr>
      <p>
        Ce site e-commerce de vente de panneaux bois sur mesure s'est lancé dans
        une deuxième refonte, dans le but de revoir grandement ses fonctions. Au
        menu : amélioration de l'UI/UX, passage au responsive, système de
        concours pour les clients qui partagent leurs réalisations, et
        configurateur sur mesure.
      </p>
      <p>
        Un des points clés de l'UI/UX est l'accès facile à la définition et aux
        modifications des dimensions ; le client peut ainsi définir les
        dimensions qu'il souhaite dès la page de liste de produits. Les
        dimensions définies sont enregistrées, accessibles et modifiables
        partout : pages détails, panier, etc.
      </p>
      <p>
        Et pour les coupes complexes, le configurateur est fait pour acompagner
        le client étape par étape.
      </p>
      <ul>
        <li>
          Système de concours pour les clients qui partagent leurs réalisations
        </li>
        <li>Configurateur sur mesure</li>
        <li>Conception ergonomique</li>
        <li>Développement de nouvelles fonctionnalités</li>
        <li>Développement du site & du back office</li>
        <li>Direction artisitique & webdesign</li>
        <li>Intégration html/css responsive</li>
      </ul>
    </template>
    <template #textJp>
      <p>
        木材のオンラインショップであるこのサイトは、今回のリニューアルで大幅な機能の見直しを行いました。
      </p>
      <p>
        UI/UXの改善、レスポンシブデザインへの対応、商品購入者の作品の共有モジュール、そして製品をカスタマイズするコンフィギュレーターの開発を行いました。
      </p>
      <p>
        UI/UXでは特に、木材のサイズの決定・変更をスムーズに行える様に、カテゴリー・詳細・カートの画面から変更出来る工夫がなされています。
      </p>
      <p>
        また、カスタマイズを一歩踏み込んで行うコンフィギュレーターにより、複雑なカッティングや細部のカスタマイズが可能となりました。
      </p>
      <ul>
        <li>UI/UXの見直し</li>
        <li>VI制作</li>
        <li>アートディレクション</li>
        <li>サイトデザイン</li>
        <li>フロントエンド開発</li>
        <li>バックエンド開発</li>
        <li>カスタムモジュール開発</li>
      </ul>
    </template>
    <template #video>
      <source src="../assets/video/bb-1-2.mp4" type="video/mp4" />
    </template>
    <template #h2-1>{{ $t("work1.title1") }}</template>
    <template #h2-2>{{ $t("work1.title2") }}</template>
    <template #text2Fr>
      <p>
        Pour répondre aux personnalisations plus poussées, le configurateur
        accompagne les clients étape par étape : découpes externes, découpes
        internes, usinage, finition, etc., avec une visualisation du résultat
        final.
      </p>
      <p>
        On peut également enregistrer les plans personnalisés et les modifier.
      </p>
    </template>
    <template #text2Jp>
      <p>
        より複雑なカスタマイズに応えるために開発されたのが、コンフィギュレーターです。
      </p>
      <p>
        複雑な形状、穴あけ、断面加工、仕上げ等を、順を追って入力出来る様購入者を誘導します。
        出来上がりイメージを見ながら一つずつ決めていけるので、想像と違えば手直しが直ぐに出来ます。
      </p>
    </template>
  </works-frame>
</template>
<script>
import WorksFrame from "./WorksFrame.vue";
export default {
  components: { WorksFrame },
  name: "Work1",
  data() {
    return {
      articleID: 1,
    };
  },
  mounted() {
    this.$emit("emitID", this.articleID);
  },
};
</script>
