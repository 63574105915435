<template>
  <works-frame-logo :articleID="articleID">
    <template #h2>Vin de Pays d'Oc</template>
    <template #h3> <span>#print</span></template>
    <template #sub>Concours</template>
    <template #date>2007</template>
    <template #intro>
      L'idée était d'évoquer les notes qui se cachent derrière chaque vin par
      des couleurs et par des illustrations symboliques de fruits. Le motif du
      liseré est inspiré de la croix occitane, l'emblème de l’Occitanie.<br /><br />L'idée
      a été également déclinée pour les vins blanc.
    </template>
    <template #imageTop>
      <img src="@/assets/img/works/wine-1.jpg" />
    </template>
    <template #image2><img src="@/assets/img/works/wine-2.jpg" /></template>
  </works-frame-logo>
</template>
<script>
import WorksFrameLogo from "./WorksFrameLogo.vue";
export default {
  components: { WorksFrameLogo },
  name: "WorkWine",
  data() {
    return {
      articleID: 8,
    };
  },
  mounted() {
    this.$emit("emitID", this.articleID);
  },
};
</script>