<template>
  <works-frame-logo :articleID="articleID">
    <template #h2>Colexo</template>
    <template #h3> <span>#web design</span><span>#front-end</span></template>
    <template #sub>Agence de location de véhicule</template>
    <template #date>2021</template>
    <template #intro
      >L'agence propose deux types de véhicule ; camion frigorifique et voiture
      de sport. Prenant les modèles exactes des véhicules qu'elle propose, les
      dessins tracés rendent le caractère artisanal et humain de
      l'agence.</template
    >
    <template #imageTop>
      <img src="@/assets/img/works/colexo_mac.jpg" />
    </template>
    <template #image2><img src="@/assets/img/works/colexo_web.jpg" /></template>
  </works-frame-logo>
</template>
<script>
import WorksFrameLogo from "./WorksFrameLogo.vue";
export default {
  components: { WorksFrameLogo },
  name: "WorkColexo",
  data() {
    return {
      articleID: 4,
    };
  },
  mounted() {
    this.$emit("emitID", this.articleID);
  },
};
</script>